import { string, object } from "yup";
import { useLocale } from "../../../../hooks/useLocale";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useValidationNameSchema = (): object => {
  const { t } = useLocale();
  return object().shape({
    firstName: string().required(() => t("required")),
    lastName: string().required(() => t("required")),
  });
};
export const useValidationEmailSchema = (): object => {
  const { t } = useLocale();
  return object().shape({
    email: string().required(() => t("required")),
  });
};
