import React, { PropsWithChildren, useEffect } from "react";
import i18n from "../../../i18n";
import useURLQuery from "../../../hooks/useURLQuery";

export interface ContextType {
  t?: any;
  locale?: string;
  setLocale?(value: string): void;
}

export const LocaleContext = React.createContext<ContextType>({});

const LocaleProvider = ({ children }: PropsWithChildren<any>): any => {
  const query = useURLQuery();
  const browserLanguage =
    window.navigator?.language.split("-")[0] === "de" ? "de" : "en";
  const languages = ["de", "en"];
  const language =
    query.get("lang") || localStorage.getItem("lang") || browserLanguage;
  const [locale, setLocale] = React.useState(language);
  const localizationContext = React.useMemo(
    () => ({
      t: (scope: any, options: any) => i18n.t(scope, { locale, ...options }),
      locale,
      setLocale,
    }),
    [locale]
  );

  useEffect(() => {
    if (language && languages.includes(language)) {
      setLocale(language);
      localStorage.setItem("lang", language);
    }
  }, []);

  return (
    <LocaleContext.Provider value={localizationContext}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
