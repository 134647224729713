import { FC, useContext, useEffect, useState } from "react";
import HomePageComponent from "./HomePageComponent";
import { fetchBadges, fetchPoints } from "../../../api/endpoint";
import { Badges, Challenge, User } from "./types";
import { useQuery } from "@apollo/client";
import { FETCH_BASIC_DATA_OF_GUEST } from "../../../graphql";
import { ApiResponse } from "apisauce";
import { AppContext } from "../../../AppContext";
import fetchChallenges from "../../../utils/fetchChallenges";
import { useLocale } from "../../../hooks/useLocale";

const HomePage: FC = () => {
  const { t } = useLocale();
  const { tokenState } = useContext(AppContext);
  const [token] = tokenState;
  const [points, setPoints] = useState<number | undefined>(0);
  const [badges, setBadges] = useState<Badges[]>([]);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const { data, loading, refetch } = useQuery(FETCH_BASIC_DATA_OF_GUEST);
  const profile = data?.guest?.profile;
  const userData = {
    id: profile?.id,
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    email: profile?.email,
  } as User;
  const doneChallenges = badges?.filter(
    (b) => b.status.completed && challenges.find((c) => c.badge === b._id)
  ).length;
  const challengesToDo = challenges.length - doneChallenges;
  const avatar = data?.guest?.profile?.avatar || "";

  const summaryInfo = [
    {
      number: points,
      text: t("winPoints"),
    },
    {
      number: doneChallenges,
      text: t("challengesDone"),
    },
    {
      number: challengesToDo,
      text: t("challengesTodo"),
    },
  ];
  useEffect(() => {
    if (token) {
      fetchChallenges()
        .then((json) => setChallenges(json?.challenges || []))
        .catch((err) => console.log(err));
      fetchBadges()
        .then((res: ApiResponse<any>) => {
          console.log(res);
          const { data } = res?.data;
          res?.ok && data && setBadges(data as Badges[]);
        })
        .catch((err) => console.log(err));
      fetchPoints()
        .then((res: ApiResponse<any>) => {
          const { data } = res?.data;
          res?.ok && data && setPoints(data);
        })
        .catch((err) => console.log(err));
    }
  }, [token]);

  return loading ? (
    <></>
  ) : (
    <HomePageComponent
      {...{
        token,
        userData,
        summaryInfo,
        challenges,
        badges,
        awardedCount: doneChallenges,
        avatar,
        refetchUserData: refetch,
      }}
    />
  );
};

export default HomePage;
