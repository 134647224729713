import { useEffect, useState } from "react";

const getWindowWidth = (): { width: number } => {
  const { innerWidth: width } = window;
  return {
    width,
  };
};

const useWindowWidth = (): { width: number } => {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    const handleResize = (): void => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => setWindowWidth(getWindowWidth()), 150);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
};

export default useWindowWidth;
