import { gql } from "@apollo/client";

export const FETCH_BASIC_DATA_OF_GUEST = gql`
  query GetBasicDataOfGuest {
    guest {
      profile {
        id
        firstName
        lastName
        phoneNumber
        language
        email
        avatar
      }
      paymentMethods {
        id
      }
    }
  }
`;

export const SAVE_PROFILE_DETAILS = gql`
  mutation SaveProfileDetails($input: SaveProfileDetailsInput!) {
    saveProfileDetails(input: $input) {
      profile {
        firstName
        lastName
      }
    }
  }
`;

export const SAVE_USER_EMAIL = gql`
  mutation SaveEmail($input: SaveProfileDetailsInput!) {
    saveProfileDetails(input: $input) {
      profile {
        email
      }
    }
  }
`;

export const SAVE_GUEST_AVATAR = gql`
  mutation sA($avatar: SaveAvatarInput!) {
    saveAvatar(input: $avatar) {
      profile {
        avatar
      }
    }
  }
`;
