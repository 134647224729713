import { FC, useContext, useEffect } from "react";
import { AppContext } from "../../../AppContext";
import { Flex } from "reflexbox";
import { getLock } from "../../../utils/loginRelated";
import { toast } from "react-toastify";
import { colors } from "../../../const/colors";
import { useLocale } from "../../../hooks/useLocale";

const AuthenticatedPage: FC = () => {
  const { locale } = useLocale();
  const { tokenState } = useContext(AppContext);
  const [token, setToken] = tokenState;

  const lock = getLock(locale || "de");
  const checkError = (error: any): void => {
    const errDesc = error?.error_description;
    if (error) {
      if (errDesc !== "Login required" && error?.statusCode === 429) {
        toast("error 429", { type: "error" });
      } else if (errDesc && errDesc !== "Login required") {
        toast(errDesc, { type: "error" });
      }
      console.log(error);
    }
  };
  useEffect(() => {
    if (!token)
      lock.checkSession({}, function (error, authResult) {
        setTimeout(() => {
          if (!authResult || !token) {
            lock.show();
            lock.on("hide", function () {
              lock.hide();
            });
            lock.on("authenticated", function (authResult) {
              if (authResult && authResult.accessToken) {
                setToken(authResult.accessToken);
                localStorage.setItem("token", authResult.accessToken);
              }
            });
          } else if (error) {
            error && checkError(error);
          }
        }, 1000);
      });
  }, [token]);

  return (
    <Flex width={"100vw"} height={"100vh"} bg={colors.backgroundSecondary} />
  );
};

export default AuthenticatedPage;
