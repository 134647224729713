import React, { FC } from "react";
import { useSelect } from "downshift";
import { useLocale } from "../../../hooks/useLocale";
import DropdownList from "../../atoms/DropdownList";
import { DropdownOption } from "../../atoms";
import { NavOption } from "./styled";
import { Box } from "reflexbox";

const LanguageSelect: FC = () => {
  const { setLocale, locale } = useLocale();

  const items = [
    {
      label: "de",
      value: "de",
    },
    {
      label: "en",
      value: "en",
    },
  ];
  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } =
    useSelect({
      items,
      id: "languageSelect",
      onSelectedItemChange: async ({ selectedItem }) => {
        if (selectedItem && selectedItem.label !== locale) {
          setLocale && (await setLocale(selectedItem.value));
          localStorage.setItem("lang", selectedItem.value);
        }
      },
    });

  return (
    <div style={{ position: "relative" }}>
      <NavOption isOpen={isOpen} {...getToggleButtonProps()}>
        <Box color={"white"} fontSize={[16, 20]}>
          {String(locale).toUpperCase()}
        </Box>
      </NavOption>
      <DropdownList width={"100%"} isOpen={isOpen} {...getMenuProps()}>
        {items.map((item, index) => (
          <DropdownOption
            key={`${item.label}${index}`}
            {...getItemProps({ item, index })}
            isSelect={locale === item.label}
            uppercase
            centerText
          >
            {item.label}
          </DropdownOption>
        ))}
      </DropdownList>
    </div>
  );
};

export default LanguageSelect;
