import { FC, useContext } from "react";
import { AppContext } from "../../../AppContext";
import AuthenticatedPage from "../../pages/AuthenticatedPage";

const AuthenticatedWrapper: FC<{ children: JSX.Element }> = ({ children }) => {
  const { tokenState } = useContext(AppContext);
  const [token] = tokenState;

  return <>{token ? children : <AuthenticatedPage />} </>;
};

export default AuthenticatedWrapper;
