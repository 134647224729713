import React, { FC } from "react";
import { Flex } from "reflexbox";
import styled from "@emotion/styled";
import { colors } from "../../../const/colors";
import { FaCheck } from "react-icons/fa";

const OkComponent: FC<any> = ({ onClick }) => (
  <Circle {...{ onClick }}>
    <FaCheck color={colors.primary} size={20} />
  </Circle>
);

export default OkComponent;

export const Circle = styled(Flex)`
  position: relative;
  border: 6px solid white;
  background-color: white;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  cursor: pointer;
  padding-bottom: 1px;
`;
