import { Flex } from "reflexbox";
import styled from "@emotion/styled";

export const NavOption = styled(Flex)<{
  isOpen: boolean;
  hideBorder?: boolean;
  customColorBackground?: string;
  customColorOnHover?: string;
}>`
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
