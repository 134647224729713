import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { FC } from "react";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang") === "en" ? "en" : "de";
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "accept-language": lang,
      "tickets-laax": "flemExpress",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          project: {
            merge: true,
          },
        },
      },
    },
  }),
});

const ApolloProviderWrapper: FC<{ children: JSX.Element }> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
