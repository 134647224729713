import styled from "@emotion/styled";
import { Flex, Box } from "reflexbox";
import { colors } from "../../../const/colors";

export const WidgetContainer = styled(Flex)`
  background: white;
  align-items: center;
  border-radius: 70px;
  position: relative;
  height: 140px;
  transition: width 1s;
  padding: 10px 40px;
  font-size: 20px;
`;
export const Details = styled.div`
  width: 120px;
  overflow: hidden;
`;
export const Text = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const Avatar = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;
export const DefaultAvatar = styled.div`
  position: absolute;
  right: -20px;
  bottom: -25px;
`;
export const AvatarImage = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 70px;
`;

export const GoToProfile = styled.div`
  position: absolute;
  right: 5px;
  bottom: -1px;
  border: 1px solid ${colors.primary};
  background: ${colors.primary};
  color: white;
  border-radius: 30px;
  font-size: 16px;
  padding: 5px 4px 5px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const LogIn = styled.div`
  font-size: 16px;
  color: black;
  cursor: pointer;
`;
export const ChallengeComponent = styled(Box)<{ background?: string }>`
  background-color: ${(p) => p.background || colors.primary};
  border: 2px solid black;
  box-shadow: 2px 2px 2px grey;
  width: 10px;
  height: 10px;
  margin-bottom: 3px;
  margin-right: 6px;
  border-radius: 5px;
`;
