import styled from "@emotion/styled";

const DropdownList = styled.ul<{
  isOpen: boolean;
  left?: boolean;
  customColorBackground?: string;
  width?: string;
}>`
  width: 100%;
  min-width: 60px;
  ${({ isOpen }) => (isOpen ? "" : "display: none;")}
  position: absolute;
  top: 130%;
  left: -40%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: ${({ isOpen }) => (isOpen ? 2 : 1)};
  outline: none;
  background: white;
  white-space: nowrap;
`;

export default DropdownList;
