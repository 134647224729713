import React, { FC, useContext, useEffect, useState } from "react";
import {
  UserDetails,
  Background,
  Email,
  Avatar,
  AvatarImage,
  NameContainer,
  FormContainer,
  AvatarInput,
  Buttons,
  LogoContainer,
  RightSettings,
  LogoutButton,
  Border,
} from "./styled";
import { UserDataProps } from "./types";
import mountains from "../../../../assets/MicrosoftTeams-image.png";
import { ReactComponent as Logo } from "../../../../assets/FLEM_Logo_black.svg";
import { Box } from "reflexbox";
import {
  useValidationNameSchema,
  useValidationEmailSchema,
} from "./validationSchema";
import InputField from "../../../molecules/InputField";
import { Form, Formik } from "formik";
import { CancelComponent, EditComponent, OkComponent } from "../../../atoms";
import { useMutation } from "@apollo/client";
import {
  SAVE_PROFILE_DETAILS,
  SAVE_USER_EMAIL,
  SAVE_GUEST_AVATAR,
} from "../../../../graphql";
import { toast } from "react-toastify";
import { AppContext } from "../../../../AppContext";
import { useLocale } from "../../../../hooks/useLocale";
import LanguageSelect from "../../../molecules/LanguageSelect";

const UserData: FC<UserDataProps> = ({ userData, avatar, refetchUserData }) => {
  const { t } = useLocale();
  const [saveProfileDetails] = useMutation(SAVE_PROFILE_DETAILS);
  const [SaveEmail] = useMutation(SAVE_USER_EMAIL);
  const [SaveAvatar] = useMutation(SAVE_GUEST_AVATAR);
  const [editUserName, setEditUserName] = useState(false);
  const [newAvatar, setNewAvatar] = useState<any>(undefined);
  const [editUserEmail, setEditUserEmail] = useState(false);
  const { tokenState } = useContext(AppContext);
  const [token, setToken] = tokenState;
  const validationNameSchema = useValidationNameSchema();
  const validationEmailSchema = useValidationEmailSchema();

  const getBase64 = (file: File): Promise<void> =>
    new Promise(() => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setNewAvatar(reader.result);
      };
      reader.onerror = function () {
        setNewAvatar(undefined);
      };
    });

  useEffect(() => {
    if (newAvatar) {
      SaveAvatar({
        variables: {
          avatar: {
            data: newAvatar.split(",")[1],
          },
        },
      })
        .then((res) => {
          if (res?.data.saveAvatar) {
            toast(t("avatarChanged"), { type: "success" });
            refetchUserData();
          } else toast(t("avatarNotChanged"), { type: "error" });
        })
        .catch((err) => console.log(err));
    }
  }, [newAvatar]);

  const onSubmitName = (values: {
    firstName: string;
    lastName: string;
  }): void => {
    saveProfileDetails({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
      },
    })
      .then((res) => {
        if (res?.data?.saveProfileDetails) {
          toast(t("userDataChanged"), { type: "success" });
          refetchUserData();
          setEditUserName(false);
        } else toast(t("userDataNotChanged"), { type: "error" });
      })
      .catch((err) => console.log(err));
  };

  const onSubmitEmail = (values: { email: string }): void => {
    SaveEmail({
      variables: {
        input: {
          email: values.email,
        },
      },
    })
      .then((res) => {
        if (res?.data?.SaveEmail) {
          toast(t("emailChanged"), { type: "success" });
          refetchUserData();
          setEditUserEmail(false);
        } else toast(t("emailNotChanged"), { type: "error" });
      })
      .catch((err) => console.log(err));
  };

  const logout = (): void => {
    setToken("");
    localStorage.setItem("token", "");
    window.close();
  };

  return (
    <UserDetails>
      <Background src={mountains} />
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <RightSettings>
        <LanguageSelect />
        <Border />
        <LogoutButton onClick={logout}>{t("logout")}</LogoutButton>
      </RightSettings>
      <div style={{ position: "relative" }}>
        {avatar ? <AvatarImage src={avatar} /> : <Avatar> + </Avatar>}
        <div style={{ position: "absolute", right: 0, top: "70%" }}>
          <div style={{ position: "relative" }}>
            <EditComponent />
            <AvatarInput
              type="file"
              name="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(image) => {
                image.target.files && getBase64(image.target.files[0]);
              }}
            />
          </div>
        </div>
      </div>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={1}
      >
        <Formik
          {...{
            initialValues: userData,
            onSubmit: onSubmitName,
            validationSchema: validationNameSchema,
          }}
        >
          {({ handleSubmit }) => (
            <Form>
              <NameContainer px={editUserName ? [2, 2, 50, 50] : 50}>
                {!editUserName ? (
                  <Box>{userData.firstName + " " + userData.lastName}</Box>
                ) : (
                  <FormContainer pt={"18px"}>
                    <InputField
                      name={"firstName"}
                      placeholder={t("firstName")}
                      wrapperStyle={{
                        width: [7 / 10, 7 / 10, 1 / 2],
                        pr: [0, 0, 2],
                      }}
                    />
                    <InputField
                      name={"lastName"}
                      placeholder={t("lastName")}
                      wrapperStyle={{
                        width: [7 / 10, 7 / 10, 1 / 2],
                      }}
                    />
                  </FormContainer>
                )}
                <Buttons>
                  {editUserName ? (
                    <>
                      <Box pb={2}>
                        <OkComponent onClick={handleSubmit} />
                      </Box>
                      <CancelComponent onClick={() => setEditUserName(false)} />
                    </>
                  ) : (
                    <EditComponent onClick={() => setEditUserName(true)} />
                  )}
                </Buttons>
              </NameContainer>
            </Form>
          )}
        </Formik>
        <Box width={1} display={"flex"} justifyContent={"center"}>
          <Formik
            {...{
              initialValues: {
                email: userData.email || "",
              },
              onSubmit: onSubmitEmail,
              validationSchema: validationEmailSchema,
            }}
          >
            {({ handleSubmit }) => (
              <Form>
                <Email>
                  {!editUserEmail ? (
                    userData.email
                  ) : (
                    <FormContainer>
                      <InputField
                        name={"email"}
                        placeholder={t("email")}
                        wrapperStyle={{
                          width: [8 / 10],
                        }}
                      />
                    </FormContainer>
                  )}

                  {/*<Flex pl={2}>*/}
                  {/*  {editUserEmail ? (*/}
                  {/*    <Flex pb={"16px"}>*/}
                  {/*      <Box pr={2}>*/}
                  {/*        <OkComponent onClick={handleSubmit} />*/}
                  {/*      </Box>*/}
                  {/*      <CancelComponent*/}
                  {/*        onClick={() => setEditUserEmail(false)}*/}
                  {/*      />*/}
                  {/*    </Flex>*/}
                  {/*  ) : (*/}
                  {/*    <EditComponent*/}
                  {/*      onClick={() => setEditUserEmail(!editUserEmail)}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*</Flex>*/}
                </Email>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </UserDetails>
  );
};

export default UserData;
