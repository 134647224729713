import React, { FC, HTMLAttributes } from "react";
import styled from "@emotion/styled";
import { Box } from "reflexbox";
import { colors } from "../../../const/colors";

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  readonly type?: string;
  value?: any;
}

const Input = styled(Box)<InputProps>`
  width: 100%;
  background-color: transparent;
  border: 1px solid ${colors.primaryLight};
  color: ${colors.primaryLight};
  border-radius: 10px;
  ::placeholder,::-webkit-input-placeholder {
    color: ${colors.primaryLight};
  }
  :-ms-input-placeholder {
     color: ${colors.primaryLight};
  }
}
`;
Input.defaultProps = {
  as: "input",
  fontSize: ["20px", "20px", "35px"],
  p: "8px",
};
export default Input;
