import React, { FC } from "react";
import { Flex } from "reflexbox";
import styled from "@emotion/styled";
import { colors } from "../../../const/colors";

const CancelComponent: FC<any> = ({ onClick }) => (
  <Circle {...{ onClick }}>+</Circle>
);

export default CancelComponent;

export const Circle = styled(Flex)`
  justify-content: center;
  align-items: center;
  border: 6px solid white;
  background-color: white;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  color: ${colors.primary};
  font-size: 30px;
  transform: rotate(45deg);
  padding-bottom: 3px;
  padding-left: 1px;
  cursor: pointer;
`;
