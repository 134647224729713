type Colors = {
  primary: string;
  primaryLight: string;
  disabled: string;
  noActive: string;
  backgroundSecondary: string;
};

export const colors: Colors = {
  primary: "#6032ff",
  primaryLight: "#c6b5ff",
  disabled: "#8f9299",
  noActive: "#363a48",
  backgroundSecondary: "#1f2431",
};
