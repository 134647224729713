export const SERVER_LAX_URL =
  "https://eu-central-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/jbmproducts-xsmnn/service/rental/incoming_webhook";
export const SERVER_BASE_URL = "https://api.laax.com";

export const SERVER_INSIDELAAX_URL = "https://insidelaax.typeform.com";

export const REACT_APP_AUTH_DOMAIN = "login.laax.com";
export const REACT_APP_AUTH_CLIENT_ID = "OrgqPPDk7oZ0b8b0NLQ7a6lXWwHdCjU9";

export const SERVER_TYPEFORM_URL = "https://api.typeform.com";

export const AUTHENTICATION_KEY = "H1E1nnX5w9s9rv3r";

export const ROUTER_BASENAME = "/game";
