import { Auth0LockPasswordless } from "auth0-lock";
import { REACT_APP_AUTH_CLIENT_ID, REACT_APP_AUTH_DOMAIN } from "../config";
const domain = `${REACT_APP_AUTH_DOMAIN ?? ""}` ?? "";
const clientId = `${REACT_APP_AUTH_CLIENT_ID ?? ""}` ?? "";

const options = {
  configurationBaseUrl: "https://cdn.eu.auth0.com",
  allowedConnections: ["facebook", "email"],
  passwordlessMethod: "code",
  auth: {
    autoParseHash: true,
    redirect: false,
    responseType: "token",
    params: {
      scope: "openid email",
    },
  },
  theme: {
    logo: "https://s3.eu-central-1.amazonaws.com/laax-image-repo/logos/laax-200.png",
    primaryColor: "#333333",
  },
  language: "de",
  languageDictionary: {
    title: "Flem Express",
  },
  avatar: null,
  autofocus: true,
  autoclose: true,
  rememberLastLogin: false,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

export const getLock = (language: string): Auth0LockPasswordlessStatic =>
  new Auth0LockPasswordless(clientId, domain, { ...options, language });
