import { FC } from "react";
import FieldWrapper from "../../molecules/FieldWrapper";
import Input from "../../atoms/Input";

interface InputFieldProps {
  type?: string;
  label?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  wrapperStyle?: any;
}

const InputField: FC<InputFieldProps> = ({
  type = "text",
  label,
  name,
  disabled,
  required = false,
  placeholder,
  wrapperStyle,
}) => (
  <FieldWrapper<string> {...{ label, name, disabled, required, wrapperStyle }}>
    {(field: any) => <Input {...{ type, disabled, placeholder, ...field }} />}
  </FieldWrapper>
);

export default InputField;
