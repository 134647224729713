import apisauce, { ApiResponse } from "apisauce";
import { SERVER_BASE_URL, SERVER_INSIDELAAX_URL } from "../config";
import { GrantBadgeData } from "./endpoint.types";

export const apiLax = apisauce.create({
  baseURL: SERVER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30 * 1000,
});

export const apiInsideLax = apisauce.create({
  baseURL: SERVER_INSIDELAAX_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30 * 1000,
});

apiLax.addAsyncRequestTransform((request) => async () => {
  const token = localStorage.getItem("token");
  if (token) request.params = { ...request.params, accessToken: token };
});

export const fetchBadges = (): Promise<ApiResponse<any, any>> =>
  apiLax.post(`/user/get-badges`);

export const fetchPoints = (): Promise<ApiResponse<any, any>> =>
  apiLax.get(`/loyalty/get-points-balance`);

export const grantBadge = (
  data: GrantBadgeData
): Promise<ApiResponse<any, any>> => apiLax.post(`/play/grant-badge`, data);
