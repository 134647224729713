import styled from "@emotion/styled";
import { Flex, Box } from "reflexbox";
import { colors } from "../../../const/colors";
import { ButtonBack, ButtonNext, Slider } from "pure-react-carousel";

export const Container = styled(Box)`
  width: 100%;
  min-height: 100vh;
`;

export const ChallengesSummary = styled(Flex)`
  width: 100%;
  background-color: white;
  justify-content: center;
  align-items: center;
  min-height: 27vh;
  position: relative;
  flex-wrap: wrap;
  padding: 14px;
`;

export const SummaryInfo = styled(Flex)<{ last: boolean }>`
  position: relative;
  min-height: 140px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
SummaryInfo.defaultProps = {
  width: [1, "33%", "33%", "20%"],
  p: ["10px", "10px"],
};
export const LineHorizontal = styled(Box)<{ first: boolean }>`
  position: absolute;
  width: 100%;
  background-color: ${colors.primary};
  top: 0;
  right: 0;
  ${({ first }) => first && `display: none}`};
`;
LineHorizontal.defaultProps = {
  height: [2, 0],
};
export const LineVertical = styled(Box)<{ last: boolean }>`
  position: absolute;
  height: 100%;
  background-color: ${colors.primary};
  top: 0;
  right: 0;
  ${({ last }) => last && `display: none}`};
`;
LineVertical.defaultProps = {
  width: [0, 2],
};
export const Challenges = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${colors.backgroundSecondary};
  min-height: 26vh;
  padding: 20px 0;
  z-index: 2;
`;

export const Challenge = styled(Box)<{ background?: string }>`
  background-color: ${(p) => p.background};
  border: 1px solid ${(p) => p.background};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 65px;
  position: relative;
  padding: 4px;
  margin: 0 20px;
`;
Challenge.defaultProps = {
  width: [90, 130],
  height: [90, 130],
};
export const BigText = styled(Box)`
  line-height: 0;
  text-align: center;
`;
BigText.defaultProps = {
  fontSize: ["40px", "60px", "70px", "90px"],
  color: colors.primary,
  py: [24, 46],
};

export const MediumText = styled(Box)``;
MediumText.defaultProps = {
  fontSize: ["13px", "18px"],
  color: colors.primary,
};

export const SmallText = styled(Box)`
  position: absolute;
  bottom: 16px;
  font-size: 12px;
`;
SmallText.defaultProps = {
  color: "white",
};

export const Arrow = styled(Box)`
  top: -9px;
  right: calc(50% - 5px);
  position: absolute;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid white;
`;

export const Line = styled.div<{ rotate?: string }>`
  position: absolute;
  width: 140px;
  height: 2px;
  background-color: ${colors.backgroundSecondary};
  transform: rotate(${(p) => p.rotate || "45deg"});
  top: 50%;
`;

export const Square = styled(Flex)<{
  rotate?: string;
  top?: string;
  right?: string;
}>`
  align-items: center;
  justify-content: center;
  top: -15px;
  right: 20%;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: ${colors.disabled};
  color: white;
  font-size: 22px;
  transform: rotate(${(p) => p.rotate || "0"});
`;

export const CleanButtonBack = styled(ButtonBack)`
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  left: -30px;
  z-index: 2;
`;

export const CleanButtonNext = styled(ButtonNext)`
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: -30px;
  z-index: 2;
`;

export const CarouselContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  position: relative;
`;
CarouselContainer.defaultProps = {
  pr: ["20px", 0],
};
export const StyledSlider = styled(Box)``;
StyledSlider.defaultProps = {
  as: Slider,
  height: [90, 130],
};
