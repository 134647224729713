import { ReactElement } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import AuthenticatedWrapper from "./components/templates/AuthenticatedWrapper";
import Widget from "./components/pages/Widget";
import GrantBadgePage from "./components/pages/GrantBadgePage";

const AppRouter = (): ReactElement => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedWrapper>
            <HomePage />
          </AuthenticatedWrapper>
        }
      />
      <Route path="/widget" element={<Widget />} />
      <Route path="/challenge-done" element={<GrantBadgePage />} />
    </Routes>
  );
};

export default AppRouter;
