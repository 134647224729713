import React, { ReactElement, useEffect } from "react";
import { useField, FieldInputProps, FieldHelperProps } from "formik";
import { Box, Flex } from "reflexbox";

export type FieldType = string | string[] | number | boolean | Date;

interface FieldWrapperProps<T> {
  label?: string;
  name: string;
  children(
    field: FieldInputProps<T>,
    helpers: FieldHelperProps<T>
  ): ReactElement;
  onChange?: (value: T) => void;
  required: boolean;
  wrapperStyle?: any;
}

function FieldWrapper<T extends FieldType>({
  children,
  label,
  name,
  onChange,
  required,
  wrapperStyle,
}: FieldWrapperProps<T>): ReactElement {
  const [field, meta, helpers] = useField<T>(name);
  const showError = meta.error && meta.touched;
  const requiredSign = required ? "*" : "";

  useEffect(() => {
    if (onChange && field.value) onChange(field.value);
  }, [field.value]);

  return (
    <Box width={1} {...wrapperStyle}>
      {label !== undefined && (
        <label htmlFor={label}>
          {label} <span style={{ color: "#c40404" }}>{requiredSign}</span>
        </label>
      )}
      <Box width={1} display={"flex"} alignItems={"flex-start"}>
        {children && children(field, helpers)}
      </Box>
      <Flex height={"20px"} alignItems={"flex-start"}>
        {showError && <span style={{ color: "white" }}>{meta.error}</span>}
      </Flex>
    </Box>
  );
}

export default FieldWrapper;
