import { FC, useState, createContext } from "react";
const AppContext = createContext<any>(undefined);

const AppProvider: FC<any> = ({ children }): JSX.Element => {
  const [token, setToken] = useState(localStorage.getItem("token"));

  return (
    <AppContext.Provider
      value={{
        tokenState: [token, setToken],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
