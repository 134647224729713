import styled from "@emotion/styled";
import { Flex, Box } from "reflexbox";
import { colors } from "../../../../const/colors";

export const Background = styled(Box)<{ src: string }>`
  position: absolute;
  width: 100%;
  object-fit: cover;
  top: 0;
  mix-blend-mode: soft-light;
`;
Background.defaultProps = {
  as: "img",
  height: ["420px", "420px", "620px"],
};

export const LogoutButton = styled(Box)`
  cursor: pointer;
  font-weight: bold;
  font-family: Arial;
  color: white;
  text-transform: uppercase;
`;
LogoutButton.defaultProps = {
  fontSize: [16, 20],
};
export const Border = styled(Box)`
  height: 20px;
  width: 2px;
  background: white;
`;
Border.defaultProps = {
  mx: [2, 2, 2, 16],
};
export const RightSettings = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 18px;
`;

export const UserDetails = styled(Box)`
  background-color: ${colors.primary};
  width: 100%;
  min-height: 47vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
`;

export const Email = styled(Flex)`
  font-size: 16px;
  color: ${colors.primaryLight};
  justify-content: center;
  height: 30px;
  position: relative;
`;
Email.defaultProps = {
  width: [1, 1, 1, "800px"],
};
export const NameContainer = styled(Flex)`
  width: fit-content;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  min-height: 98px;
`;
NameContainer.defaultProps = {
  fontSize: ["24px", "60px", "70px"],
  color: colors.primaryLight,
};

export const Avatar = styled(Flex)`
  font-family: Arial;
  align-items: center;
  justify-content: center;
  border: 6px solid ${colors.primaryLight};
  color: ${colors.primaryLight};
  font-size: 100px;
  line-height: 0;
  width: 130px;
  height: 130px;
  border-radius: 65px;
`;

export const AvatarImage = styled.img`
  width: 130px;
  height: 130px;
  border: 6px solid ${colors.primaryLight};
  border-radius: 68px;
`;

export const FormContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const AvatarInput = styled.input`
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  ::-webkit-file-upload-button {
    cursor: pointer;
  }
`;
export const Buttons = styled(Box)`
  position: absolute;
  right: 15px;
`;

export const LogoContainer = styled(Box)`
  position: absolute;
  top: 10px;
  left: 14px;
`;

LogoContainer.defaultProps = {
  width: ["70px", "70px", "150px"],
};
