import { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import { ChallengeComponent } from "./styled";
import fetchChallenges from "../../../utils/fetchChallenges";
import { Badges, Challenge } from "../HomePage/types";
import { fetchBadges } from "../../../api/endpoint";
import { ApiResponse } from "apisauce";
import { colors } from "../../../const/colors";

const Challenges: FC = () => {
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [badges, setBadges] = useState<Badges[]>([]);

  useEffect(() => {
    fetchChallenges().then((json) => setChallenges(json?.challenges || []));
    fetchBadges().then((res: ApiResponse<any>) => {
      const { data } = res?.data;
      res?.ok && data && setBadges(data as Badges[]);
    });
  }, []);

  return (
    <Flex width={1} pt={"6px"} flexWrap={"wrap"}>
      {challenges.map((c, index) => {
        const challengeCompleted = Boolean(
          badges.find((b) => b._id === c.badge)?.status?.completed
        );
        return (
          <ChallengeComponent
            background={challengeCompleted ? colors.disabled : colors.primary}
            key={"challenge" + index}
          />
        );
      })}
    </Flex>
  );
};

export default Challenges;
