import { FC, useEffect } from "react";
import useURLQuery from "../../../hooks/useURLQuery";
import { grantBadge } from "../../../api/endpoint";
import { AUTHENTICATION_KEY } from "../../../config";

const GrantBadgePage: FC = () => {
  const query = useURLQuery();
  const userId = query.get("userId");
  const badgeId = query.get("badgeId");
  const authenticationKey = AUTHENTICATION_KEY;

  useEffect(() => {
    if (userId && badgeId) grantBadge({ authenticationKey, userId, badgeId });
  }, [userId, badgeId]);

  return <></>;
};

export default GrantBadgePage;
