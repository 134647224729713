import styled from "@emotion/styled";
import { colors } from "../../../const/colors";

const DropdownOption = styled.li<{
  isSelect?: boolean;
  uppercase?: boolean;
  centerText?: boolean;
}>`
  font-weight: ${({ isSelect }) => (isSelect ? "bold" : "normal")};
  color: ${({ isSelect }) => (isSelect ? colors.primary : colors.disabled)};
  ${({ uppercase }) => (uppercase ? "text-transform: uppercase;" : "")}
  padding: 10px;
  ${({ centerText }) => (centerText ? "text-align: center;" : "")}
  cursor: pointer;
  background: white;
  &:hover {
    background: 'grey;
  }
`;

export default DropdownOption;
