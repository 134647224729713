import { FC, useContext, useState } from "react";
import { Box } from "reflexbox";
import { AppContext } from "../../../AppContext";
import svg from "../../../assets/figur-8.svg";
import { useQuery } from "@apollo/client";
import { FETCH_BASIC_DATA_OF_GUEST } from "../../../graphql";
import {
  WidgetContainer,
  Avatar,
  DefaultAvatar,
  AvatarImage,
  Details,
  GoToProfile,
  LogIn,
  Text,
} from "./styled";
import { FaAngleRight } from "react-icons/fa";
import { ROUTER_BASENAME } from "../../../config";
import Challenges from "./Challenges";
import { useLocale } from "../../../hooks/useLocale";

const Widget: FC = () => {
  const { t, locale } = useLocale();
  const { tokenState } = useContext(AppContext);
  const [token] = tokenState;
  const narrowWidgetSize = 140;
  const wideWidgetSize = 350;
  const [widthWidget, setWidthWidget] = useState(narrowWidgetSize);
  const { data, loading } = useQuery(FETCH_BASIC_DATA_OF_GUEST);
  const profile = data?.guest?.profile;

  return (
    <Box
      bg={"transparent"}
      height={142}
      display={"flex"}
      alignItems={"flex-end"}
      justifyContent={"flex-end"}
      style={{ overflow: "hidden" }}
    >
      {!loading && (
        <WidgetContainer
          style={token ? {} : { overflowX: "hidden", overflowY: "hidden" }}
          width={widthWidget + "px"}
          onMouseEnter={() => {
            setWidthWidget(wideWidgetSize);
          }}
          onMouseLeave={() => {
            setWidthWidget(narrowWidgetSize);
          }}
        >
          <Details>
            {token ? (
              <>
                <Text>{profile?.firstName}</Text>
                <Text>{profile?.lastName}</Text>
                <Challenges />
              </>
            ) : (
              <LogIn
                onClick={() =>
                  window.open(
                    window.origin + ROUTER_BASENAME + "?lang=" + locale
                  )
                }
              >
                {t("login")}
              </LogIn>
            )}
          </Details>

          {token && profile?.avatar ? (
            <Avatar>
              <AvatarImage src={profile?.avatar} />
            </Avatar>
          ) : (
            <DefaultAvatar>
              <img src={svg} />
            </DefaultAvatar>
          )}

          {token && (
            <GoToProfile
              onClick={() =>
                window.open(window.origin + ROUTER_BASENAME + "?lang=" + locale)
              }
            >
              {t("toTheProfile")} <FaAngleRight color={"white"} size={24} />
            </GoToProfile>
          )}
        </WidgetContainer>
      )}
    </Box>
  );
};

export default Widget;
