import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRouter from "./AppRouter";
import "react-toastify/dist/ReactToastify.css";
import { AppProvider } from "./AppContext";
import "pure-react-carousel/dist/react-carousel.es.css";
import ApolloProviderWrapper from "./components/atoms/ApollProviderWrapper";
import { ReactElement } from "react";
import { ROUTER_BASENAME } from "./config";
import { LocaleProvider } from "./components/atoms";
import "./i18n";

const App = (): ReactElement => {
  return (
    <AppProvider>
      <ApolloProviderWrapper>
        <BrowserRouter basename={ROUTER_BASENAME}>
          <LocaleProvider>
            <AppRouter />
            <ToastContainer />
          </LocaleProvider>
        </BrowserRouter>
      </ApolloProviderWrapper>
    </AppProvider>
  );
};

export default App;
