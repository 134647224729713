import React, { FC } from "react";
import {
  Container,
  ChallengesSummary,
  Challenges,
  BigText,
  SummaryInfo,
  MediumText,
  Arrow,
  Challenge,
  Line,
  SmallText,
  Square,
  CleanButtonBack,
  CleanButtonNext,
  StyledSlider,
  LineHorizontal,
  CarouselContainer,
  LineVertical,
} from "./styled";
import { HomePageComponentProps } from "./types";
import { colors } from "../../../const/colors";
import { CarouselProvider, Slide } from "pure-react-carousel";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import useWindowWidth from "../../../hooks/useWindowWidth";
import breakpoints from "../../../const/breakpoints";
import UserData from "./UserData";

const HomePageComponent: FC<HomePageComponentProps> = ({
  summaryInfo,
  challenges,
  badges,
  awardedCount,
  userData,
  avatar,
  refetchUserData,
  token,
}) => {
  const { width } = useWindowWidth();
  const getPreferSlideNumber = (n: number): number =>
    challenges.length < n ? challenges.length : n;
  const visibleSlides =
    width < breakpoints[0]
      ? getPreferSlideNumber(2)
      : width < breakpoints[1]
      ? getPreferSlideNumber(3)
      : width < breakpoints[2]
      ? getPreferSlideNumber(4)
      : getPreferSlideNumber(5);
  const showArrow = visibleSlides < challenges.length;

  return (
    <Container>
      <UserData
        userData={userData}
        avatar={avatar}
        refetchUserData={refetchUserData}
      />
      <ChallengesSummary>
        <Arrow />
        <Square rotate={"30deg"} />
        <Square rotate={"60deg"} />
        <Square>{awardedCount}</Square>
        {summaryInfo &&
          summaryInfo.map((info, index) => (
            <SummaryInfo
              last={index === summaryInfo.length - 1}
              key={"summaryInfo" + index}
            >
              <LineHorizontal first={index === 0} />
              <LineVertical last={index === summaryInfo.length - 1} />
              <BigText>{info.number}</BigText>
              <MediumText minHeight={[30, 50]} fontSize={"20px"}>
                {info.text}
              </MediumText>
            </SummaryInfo>
          ))}
      </ChallengesSummary>
      <Challenges>
        <CarouselProvider
          naturalSlideWidth={110}
          naturalSlideHeight={110}
          totalSlides={challenges.length}
          orientation="horizontal"
          interval={1000}
          dragEnabled={true}
          visibleSlides={visibleSlides}
        >
          <CarouselContainer>
            {showArrow && (
              <CleanButtonBack>
                <FaAngleLeft color="white" size={"50px"} />
              </CleanButtonBack>
            )}

            <StyledSlider
              width={[
                (220 * visibleSlides) / 2,
                (500 * visibleSlides) / 3,
                (700 * visibleSlides) / 4,
                (900 * visibleSlides) / 5,
              ]}
            >
              {challenges &&
                challenges.map((challenge, index) => {
                  const challengeCompleted = Boolean(
                    badges.find((b) => b._id === challenge.badge)?.status
                      ?.completed
                  );
                  return (
                    <Slide index={index} key={"Slide" + index}>
                      <Challenge
                        style={{
                          cursor: challengeCompleted ? "default" : "pointer",
                        }}
                        onClick={() =>
                          !challengeCompleted &&
                          window.open(
                            challenge.url +
                              `#userid=${userData.id}&token=${token}`,
                            "_blank"
                          )
                        }
                        background={
                          challengeCompleted ? colors.disabled : colors.primary
                          // : challenge.active
                          // ? colors.disabled
                          // : colors.noActive
                        }
                      >
                        {true ? (
                          <>
                            <MediumText
                              color={challengeCompleted ? "white" : "white"}
                            >
                              {challenge.badge}
                            </MediumText>
                            <SmallText
                              color={challengeCompleted ? "white" : "white"}
                            >
                              {challengeCompleted
                                ? challenge.points
                                : challenge.points}
                            </SmallText>
                          </>
                        ) : (
                          <>
                            <Line />
                            <Line rotate={"-45deg"} />
                          </>
                        )}
                      </Challenge>
                    </Slide>
                  );
                })}
            </StyledSlider>
            {showArrow && (
              <CleanButtonNext>
                <FaAngleRight color="white" size={"50px"} />
              </CleanButtonNext>
            )}
            {/*<div style={{ position: "relative" }}>*/}
            {/*  <iframe*/}
            {/*    src={"http://localhost:3000/game/widget?lang=en"}*/}
            {/*    style={{*/}
            {/*      border: "none",*/}
            {/*      position: "absolute",*/}
            {/*      bottom: 400,*/}
            {/*      right: 0,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
          </CarouselContainer>
        </CarouselProvider>
      </Challenges>
    </Container>
  );
};

export default HomePageComponent;
