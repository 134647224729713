import React, { FC } from "react";
import { Flex } from "reflexbox";
import styled from "@emotion/styled";
import { colors } from "../../../const/colors";

const EditComponent: FC<any> = ({ onClick }) => (
  <Circle {...{ onClick }}>
    <PencilPartOne />
    <PencilPartTwo />
  </Circle>
);

export default EditComponent;

export const Circle = styled(Flex)`
  position: relative;
  border: 6px solid white;
  background-color: white;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  cursor: pointer;
`;

export const PencilPartOne = styled(Flex)`
  position: absolute;
  bottom: 1px;
  left: 2px;
  border-left: 2px solid ${colors.primary};
  border-bottom: 2px solid ${colors.primary};
  width: 7px;
  height: 7px;
`;
export const PencilPartTwo = styled(Flex)`
  position: absolute;
  bottom: 3px;
  left: 4px;
  border-top: 2px solid ${colors.primary};
  border-bottom: 2px solid ${colors.primary};
  transform: rotate(-45deg);
  width: 10px;
  height: 10px;
`;
